import React, { useEffect, useState } from "react";
import { TheBuildingSectionWrapper, TheBuildingDisplayAlignItems, TheBuildingDivContent, TheBuildingParagraphs, HoverImageMobile, SustainabilityIcon, TheBuildingCarousel, SelectedIcon, FadeInOut, TheBuildingImage, TheBuildingGrid, TheBuildingJustifyContent, TheBuildingButtonContact } from "../../../style/TheBuildingScreen";
import { sustainabilitys } from "../Data/sustainabilitys";
import parse from "html-react-parser";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";
import { Link } from "react-router-dom";
import ContactUsButtonComponent from "../../../components/ContactUsButton";

const descriptionEnergyEfficiencys = ["Facade", "Ventilation System", "Automatic Lighting Control"];

const Sustainability = ({ kf }) => {
    const { key, title, description, image } = kf;
    const { t, i18n } = useTranslation();
    const currentLanguageCode = i18n.language;
    const checkTab = key === "energy-efficiency";

    return (
        <FadeInOut key={key}>
            <TheBuildingDivContent padding={"40px 20px 20px 20px"}>
                <TheBuildingParagraphs tablet={"28px"} color={"#2C53AF"} fsize={"17px"} linehg={1.15} fweight={"600"} languagecode={currentLanguageCode} font={"'Barlow', Helvetica"}>
                    {parse(t(title))}
                </TheBuildingParagraphs>
            </TheBuildingDivContent>
            <TheBuildingDivContent padding={checkTab ? "" : "0px 20px 30px 20px"}>
                {checkTab ? (
                    <TheBuildingCarousel dots={true}>
                        {descriptionEnergyEfficiencys.map((item, index) => (
                            <TheBuildingParagraphs tablet={"18px"} key={index} fsize={"13px"} align={"justify"} linehg={"21px"} fweight={"400"} languagecode={currentLanguageCode}>
                                {parse(t(item))}
                            </TheBuildingParagraphs>
                        ))}
                    </TheBuildingCarousel>
                ) : (
                    <TheBuildingParagraphs tablet={"18px"} color={"#353535"} fsize={"13px"} align={"justify"} linehg={"21px"} fweight={"400"} languagecode={currentLanguageCode}>
                        {parse(t(description))}
                    </TheBuildingParagraphs>
                )}
            </TheBuildingDivContent>
            <TheBuildingDivContent padding={"0px 0px 30px 0px"}>
                <TheBuildingImage border={"solid 1px #535353"} alt={image} src={image} />
            </TheBuildingDivContent>
        </FadeInOut>
    );
};

const SustainabilityIconComponent = ({ kf, className, tabKey, changeTab }) => {
    const { icon } = kf;
    const iconImage = tabKey === kf.key ? icon.activated : icon.normal;
    const colorActive = tabKey === kf.key ? "#16f0df" : "#2c53af";
    const sizeIcon = kf.key === "assistive" ? "57px" : "47px";
    const { t, i18n } = useTranslation();
    const currentLanguageCode = i18n.language;

    return (
        <HoverImageMobile>
            <TheBuildingDivContent onClick={() => changeTab(kf.key)} onKeyDown={() => changeTab(kf.key)}>
                <SustainabilityIcon className={`key-feature-icon ${className ?? ""}`} alt={iconImage} src={iconImage} height={"60px"} width={sizeIcon} />
                <TheBuildingParagraphs tablet={"18px"} color={colorActive} fweight={"600"} languagecode={currentLanguageCode}>
                    {parse(t(icon.title))}
                </TheBuildingParagraphs>
            </TheBuildingDivContent>
        </HoverImageMobile>
    );
};

Sustainability.propTypes = {
    kf: PropTypes.object
};

SustainabilityIconComponent.propTypes = {
    kf: PropTypes.object,
    className: PropTypes.string,
    tabKey: PropTypes.string,
    changeTab: PropTypes.func
};

const SustainabilityMobile = () => {
    const [tabKey, setTabKey] = useState("construction");
    const [currentKey, setCurrentKey] = useState(null);
    const { t, i18n } = useTranslation();
    const currentLanguageCode = i18n.language;

    const changeTab = (index) => {
        ReactGA.event({
            category: "Click",
            action: "Click",
            label: `Sustainability selected icon ${index}`
        });
        setTabKey(index);
    };

    useEffect(() => {
        const kf = sustainabilitys.find(({ key }) => key === tabKey);
        if (kf) {
            setCurrentKey(kf);
        }
    }, [tabKey]);

    const SustainabilitySelectionPanel = () => {
        let kfs = sustainabilitys.map((item, index) => {
            return <SustainabilityIconComponent key={index} kf={item} changeTab={changeTab} tabKey={tabKey} />;
        });
        return (
            <TheBuildingGrid template={"auto auto"} tablet={"25% 25% 25% 25%"} colgap={"30px"} rwgap={"30px"}>
                {kfs}
            </TheBuildingGrid>
        );
    };

    useEffect(() => {
        i18n.changeLanguage(currentLanguageCode);
    }, []);

    return (
        <TheBuildingSectionWrapper id="sustainability">
            <TheBuildingDisplayAlignItems>
                <TheBuildingDivContent padding={"30px 30px"}>
                    <TheBuildingParagraphs fsize={"27px"} linehg={"27px"} fweight={"700"} languagecode={currentLanguageCode} font={"'Barlow', Helvetica"}>
                        {t("DESIGN FOR SUSTAINABILITY, DESIGN FOR FUTURE")}
                    </TheBuildingParagraphs>
                </TheBuildingDivContent>
                <TheBuildingDivContent padding={"0px 0px"}>
                    <TheBuildingImage width={"60px"} height={"60px"} alt="leed-silver-logo" src="./img/leed-silver-logo.png" />
                </TheBuildingDivContent>
                <TheBuildingDivContent padding={"20px 0px"}>
                    <TheBuildingParagraphs tablet={"25px"} fsize={"22px"} linehg={"22px"} fweight={"600"} languagecode={currentLanguageCode} font={"'Barlow', Helvetica"}>
                        {t("LEED CERTIFICATE")}
                    </TheBuildingParagraphs>
                </TheBuildingDivContent>
                <TheBuildingCarousel dots={true}>
                    <TheBuildingParagraphs tablet={"18px"} fsize={"13px"} align={"justify"} linehg={"21px"} fweight={"400"} languagecode={currentLanguageCode}>
                        {t("LEED CERTIFICATE DESCRIPTION")}
                    </TheBuildingParagraphs>
                    <TheBuildingParagraphs tablet={"18px"} fsize={"13px"} align={"justify"} linehg={"21px"} fweight={"400"} languagecode={currentLanguageCode}>
                        {parse(t("First Taisei Square Hanoi"))}
                    </TheBuildingParagraphs>
                    <TheBuildingParagraphs tablet={"18px"} fsize={"13px"} align={"justify"} linehg={"21px"} fweight={"400"} languagecode={currentLanguageCode}>
                        {parse(t("Second Taisei Square Hanoi"))}
                    </TheBuildingParagraphs>
                </TheBuildingCarousel>
                <TheBuildingJustifyContent padding={"0px 0px 20px 0px"}>
                    <TheBuildingButtonContact>
                        <Link to="/contact">
                            <ContactUsButtonComponent text={t("CONTACT US")} width={"150px"} textml={"12px"} />
                        </Link>
                    </TheBuildingButtonContact>
                </TheBuildingJustifyContent>
                <SustainabilitySelectionPanel />
                <SelectedIcon>{currentKey && <Sustainability kf={currentKey} />}</SelectedIcon>
            </TheBuildingDisplayAlignItems>
        </TheBuildingSectionWrapper>
    );
};

export default SustainabilityMobile;
