import { SelectionControl, ContactDivScreen, ContactDisplay, ContactDisplayPadding, ContactDivContent, ContactInfoDisplay, ContactGrid, ContactIcon, ContactParagraphs, ContactImage, FormGroup, InputControl, TextAreaControl, ContactSendButton, ContactJustifyContent, ContactSpan, Option, LinkContact } from "../../../style/ContactScreen";
import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { sendMailEvent } from "../../../api/contact";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";

const AddressSection = ({ onHeightChange, datas }) => {
    const sectionRef = useRef(null);
    const itemsSalution = [
        { label: "Mr.", value: "Mr." },
        { label: "Ms.", value: "Ms." }
    ];
    const [fullname, setFullName] = useState("");
    const [salution, setSalution] = useState(itemsSalution[0].value);
    const [email, setEmail] = useState("");
    const [phoneNo, setPhoneNo] = useState("");
    const [message, setMessage] = useState("");
    const [isButtonDisable, setIsButtonDisable] = useState(true);
    const [isSentStatus, setIsSentStatus] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const { t, i18n } = useTranslation();
    const currentLanguageCode = i18n.language;

    useEffect(() => {
        i18n.changeLanguage(currentLanguageCode);
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === "fullname") {
            setFullName(value);
        } else if (name === "email") {
            setEmail(value);
        } else if (name === "phoneNo") {
            setPhoneNo(value);
        } else if (name === "message") {
            setMessage(value);
        }
    };

    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked);
    };

    const handleSelectChange = (e) => {
        setSalution(e.target.value);
    };

    const sendMail = async () => {
        const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        const phonePattern = /^\d{10}$/;
        if (!isChecked) return toast.error("Please check you have agreed to our privacy policy!");
        if (fullname == "" || fullname == null) return toast.error("Invalid Name.");
        if (email == "" || email == null) {
            return toast.error("Email is required.");
        } else if (!emailPattern.test(email)) {
            return toast.error("Invalid Email.");
        }
        if (message == "" || message == null) return toast.error("Your message is required.");
        if (phoneNo !== "" && !phonePattern.test(phoneNo)) return toast.error("Invalid Phone No.");

        window.grecaptcha.ready(() => {
            window.grecaptcha.execute("6LdPCg0pAAAAAPi6j8Kr_XDoa8R1f1lJtNGjTEWx", { action: "submit" }).then(async () => {
                if (!isButtonDisable) {
                    if (fullname && salution && email && message) {
                        try {
                            ReactGA.event({
                                category: "Email",
                                action: "Send",
                                label: "Contact form send mail"
                            });

                            toast.info("Sending email!");
                            setIsSentStatus(true);
                            setIsButtonDisable(true);
                            const messageSendEmail = await sendMailEvent(fullname, salution, email, phoneNo, message);
                            if (messageSendEmail.success) {
                                toast.success(messageSendEmail.message);
                                toast.info("Please wait for 2 minutes to proceed with sending the email.");
                                setTimeout(
                                    () => {
                                        toast.info("You can now send email.");
                                        setIsSentStatus(false);
                                        setIsButtonDisable(false);
                                    },
                                    2 * 60 * 1000
                                );
                            } else {
                                toast.error(messageSendEmail.message);
                                setIsButtonDisable(false);
                            }
                        } catch (error) {
                            toast.error(error.message);
                            setIsButtonDisable(false);
                        }
                    }
                }
            });
        });
    };

    useEffect(() => {
        if (isChecked) {
            if (!isSentStatus) setIsButtonDisable(false);
        } else {
            setIsButtonDisable(true);
        }
    }, [isChecked, isSentStatus]);

    useEffect(() => {
        const sectionHeight = sectionRef.current ? sectionRef.current.clientHeight : 0;
        onHeightChange((prevHeight) => prevHeight + sectionHeight);
    }, [onHeightChange]);

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            const yOffset = section.getBoundingClientRect().top + window.scrollY;
            window.scrollTo({ top: yOffset, behavior: "smooth" });
        } else {
            window.scrollTo({ top: 0, behavior: "smooth" });
            setTimeout(() => {
                const second = document.getElementById(sectionId);
                const yOffset = second.getBoundingClientRect().top;
                window.scrollTo({ top: yOffset, behavior: "smooth" });
            }, 500);
        }
    };

    const openNewTabPolicy = () => {
        const currentURL = window.location.href;
        const newURL = currentURL.replace("/contact", "/privacyPolicy");
        const newTab = window.open(newURL, "_blank");
        newTab.focus();
    };

    return (
        <ContactDivScreen id="address" ref={sectionRef} padding={"10px 0px"}>
            <ContactDisplay>
                <ContactDisplayPadding direction={"row"} padding={"50px 82px 0px 82px"}>
                    <ContactDivContent width={"596px"}>
                        <ContactGrid className="grid1" width={"596px"}>
                            <ContactDivContent>
                                <ContactInfoDisplay>
                                    <ContactIcon src="./img/receiver.png" />
                                    <ContactParagraphs languagecode={currentLanguageCode} padding={"0px 5px"} font={"Barlow, Helvetica"} fweight={"600"} fsize={"27px"} linehg={"27px"}>
                                        {t("HOTLINE")}
                                    </ContactParagraphs>
                                </ContactInfoDisplay>
                                <ContactParagraphs languagecode={currentLanguageCode} align={"left"} color={"#2C53AF"} padding={"5px 0px 5px 35px"} fweight={"600"} fsize={"15px"} linehg={"25px"}>
                                    <LinkContact href="tel:0888.247.289">0888.247.289</LinkContact>
                                </ContactParagraphs>
                            </ContactDivContent>
                            <ContactDivContent>
                                <ContactInfoDisplay>
                                    <ContactIcon src="./img/email.png" />
                                    <ContactParagraphs languagecode={currentLanguageCode} padding={"0px 6px"} font={"Barlow, Helvetica"} fweight={"600"} fsize={"27px"} linehg={"27px"}>
                                        {t("INQUIRY")}
                                    </ContactParagraphs>
                                </ContactInfoDisplay>
                                <ContactParagraphs languagecode={currentLanguageCode} align={"left"} color={"#2C53AF"} padding={"5px 0px 5px 37px"} fweight={"600"} fsize={"15px"} linehg={"25px"}>
                                    <LinkContact href="mailto:inquiry@taiseidh.vn">inquiry@taiseidh.vn</LinkContact>
                                </ContactParagraphs>
                            </ContactDivContent>
                            <ContactDivContent>
                                <ContactInfoDisplay>
                                    <ContactIcon src="./img/location.png" />
                                    <ContactParagraphs languagecode={currentLanguageCode} font={"'Barlow', Helvetica"} padding={"0px 5px"} fweight={"600"} fsize={"27px"} linehg={"27px"}>
                                        {t("ADDRESS")}
                                    </ContactParagraphs>
                                </ContactInfoDisplay>
                                <ContactParagraphs languagecode={currentLanguageCode} align={"left"} color={"#2C53AF"} padding={"5px 0px 5px 35px"} fweight={"600"} fsize={"15px"} linehg={"25px"}>
                                    <LinkContact href="https://maps.app.goo.gl/4seCDHs8GN15fZ8G9" target="_blank" rel="noopener noreferrer">
                                        <p>289 Khuat Duy Tien</p>
                                        <p>Trung Hoa, Cau Giay</p>
                                        <p>Hanoi</p>
                                    </LinkContact>
                                </ContactParagraphs>
                            </ContactDivContent>
                        </ContactGrid>
                        <ContactDivContent display={"none"} padding={"70px 0px"} >
                            <div>
                                <ContactInfoDisplay>
                                    <ContactIcon src="./img/location-1.png" />
                                    <ContactParagraphs languagecode={currentLanguageCode} font={"'Barlow', Helvetica"} padding={"0px 5px"} fweight={"600"} fsize={"27px"} linehg={"27px"}>
                                        {t("UPCOMING LEASING EVENT CONTACT")}
                                    </ContactParagraphs>
                                </ContactInfoDisplay>
                            </div>
                            <ContactDivContent width={"453px"} padding={""}>
                                <Link to="/media#leasing-events" onClick={() => scrollToSection("leasing-events")}>
                                    <ContactParagraphs languagecode={currentLanguageCode} underline={"underline"} align={"left"} color={"#2C53AF"} padding={"5px 0px 5px 35px"} fweight={"600"} fsize={"15px"} linehg={"25px"}>
                                        {datas ? datas.title : `[Name of Event]`}
                                    </ContactParagraphs>
                                </Link>
                                <Link to="/media#leasing-events" onClick={() => scrollToSection("leasing-events")}>
                                    <ContactImage src={datas ? `${process.env.REACT_APP_CMS}${datas.imageUrl}` : "./img/6-hero.png"} />
                                </Link>
                            </ContactDivContent>
                        </ContactDivContent>
                    </ContactDivContent>
                    <ContactDivContent width={"596px"} padding={"0px 0px 0px 50px"}>
                        <ContactInfoDisplay>
                            <ContactIcon src="./img/email-send.png" />
                            <ContactParagraphs languagecode={currentLanguageCode} font={"'Barlow', Helvetica"} padding={"0px 5px"} fweight={"600"} fsize={"27px"} linehg={"27px"}>
                                {t("SEND A MESSAGE")}
                            </ContactParagraphs>
                        </ContactInfoDisplay>
                        <ContactInfoDisplay width={"596px"} padding={"40px 0px 0px 0px"} direction={"column"}>
                            <ContactInfoDisplay width={"596px"} direction={"row"}>
                                <ContactDivContent width={"403px"}>
                                    <ContactParagraphs languagecode={currentLanguageCode} align={"left"} padding={"10px 0px"} color={"#2C53AF"} fweight={"700"} fsize={"15px"} linehg={"15px"}>
                                        {t("NAME")} *
                                    </ContactParagraphs>
                                    <FormGroup padding={"0px 6px 0px 0px"}>
                                        <InputControl type="text" name="fullname" value={fullname} onChange={handleInputChange} />
                                    </FormGroup>
                                </ContactDivContent>
                                <ContactDivContent width={"192px"}>
                                    <ContactParagraphs languagecode={currentLanguageCode} align={"left"} padding={"10px 0px"} color={"#2C53AF"} fweight={"700"} fsize={"15px"} linehg={"15px"}>
                                        {t("SALUTATION")}
                                    </ContactParagraphs>
                                    <FormGroup>
                                        <SelectionControl value={salution || itemsSalution[0].value} onChange={handleSelectChange}>
                                            {itemsSalution.map((option) => (
                                                <Option key={option.value} value={option.value}>
                                                    {option.label}
                                                </Option>
                                            ))}
                                        </SelectionControl>
                                    </FormGroup>
                                </ContactDivContent>
                            </ContactInfoDisplay>
                            <ContactDivContent padding={"20px 0px 0px 0px"} width={"596px"}>
                                <ContactParagraphs languagecode={currentLanguageCode} align={"left"} padding={"10px 0px"} color={"#2C53AF"} fweight={"700"} fsize={"15px"} linehg={"15px"}>
                                    {t("EMAIL")} *
                                </ContactParagraphs>
                                <FormGroup>
                                    <InputControl type="text" name="email" padding={"10px"} value={email} onChange={handleInputChange} />
                                </FormGroup>
                            </ContactDivContent>
                            <ContactDivContent padding={"20px 0px 0px 0px"} width={"596px"}>
                                <ContactParagraphs languagecode={currentLanguageCode} align={"left"} padding={"10px 0px"} color={"#2C53AF"} fweight={"700"} fsize={"15px"} linehg={"15px"}>
                                    {t("PHONE NO.")}
                                </ContactParagraphs>
                                <FormGroup>
                                    <InputControl type="text" name="phoneNo" value={phoneNo} onChange={handleInputChange} />
                                </FormGroup>
                            </ContactDivContent>
                            <ContactDivContent padding={"20px 0px 0px 0px"} width={"596px"}>
                                <ContactParagraphs languagecode={currentLanguageCode} align={"left"} padding={"10px 0px"} color={"#2C53AF"} fweight={"700"} fsize={"15px"} linehg={"15px"}>
                                    {t("YOUR MESSAGE")} *
                                </ContactParagraphs>
                                <FormGroup height={"260px"}>
                                    <TextAreaControl type="text" name="message" value={message} onChange={handleInputChange} />
                                </FormGroup>
                            </ContactDivContent>
                        </ContactInfoDisplay>
                        <ContactInfoDisplay padding={"10px 0px 0px 0px"} width={"596px"} alignitems={"center"}>
                            <ContactDivContent margin={"0 5px 0 0"}>
                                <InputControl width={"20px"} type={"checkbox"} checked={isChecked} onChange={handleCheckboxChange} />
                            </ContactDivContent>
                            <ContactDivContent width={"100%"}>
                                <ContactParagraphs languagecode={currentLanguageCode} align={"left"}>
                                    {t("POLICY CONTENT")}
                                    <ContactSpan color={"#2C53AF"} underline={"underline"} cursor={"pointer"} onClick={openNewTabPolicy}>
                                        {t("PRIVACY POLICY")}
                                    </ContactSpan>
                                    {currentLanguageCode !== "en" && <ContactSpan>{t("PRIVACY POLICY CUSTOM")}</ContactSpan>}
                                </ContactParagraphs>
                            </ContactDivContent>
                        </ContactInfoDisplay>
                        <ContactJustifyContent width={"596px"} padding={"10px 0px 0px 0px"}>
                            <ContactSendButton className="contactus" color={isButtonDisable ? "#353535" : "#F4F4F4"} languagecode={currentLanguageCode} font={"'Barlow', Helvetica"} onClick={sendMail} disabled={isButtonDisable}>
                                {t("SEND")}
                            </ContactSendButton>
                        </ContactJustifyContent>
                    </ContactDivContent>
                </ContactDisplayPadding>
            </ContactDisplay>
            <ContactDivContent margin={"20px 0 145px 0"}>
                <iframe title="Google Maps" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3724.7546690087215!2d105.79377679999999!3d21.0024691!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3135ad460ef012e1%3A0x73874de77f3e7407!2sTaisei%20Square%20Hanoi!5e0!3m2!1sen!2sus!4v1698807410826!5m2!1sen!2sus" width={"100%"} height={"569px"} style={{ border: 0 }} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            </ContactDivContent>
        </ContactDivScreen>
    );
};

AddressSection.propTypes = {
    onHeightChange: PropTypes.func,
    datas: PropTypes.object,
    handleInputChange: PropTypes.func,
    sendMail: PropTypes.func,
    handleSelectChange: PropTypes.func,
    itemsSalution: PropTypes.array,
    messageSendEmail: PropTypes.object
};

export default AddressSection;
