import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import ContactUsButtonComponent from "../../../components/ContactUsButton";
import { HeroLanding, LeasingDisplay, LeasingJustifyContent, LeasingParagraphsHero, LeasingDivElement } from "../../../style/LeasingScreen";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

const HeroSection = ({ onHeightChange }) => {
    const sectionRef = useRef(null);
    const { t, i18n } = useTranslation();
    const currentLanguageCode = i18n.language;

    useEffect(() => {
        const sectionHeight = sectionRef.current ? sectionRef.current.clientHeight : 0;
        onHeightChange(sectionHeight);
    }, [onHeightChange]);

    useEffect(() => {
        i18n.changeLanguage(currentLanguageCode);
    }, []);

    return (
        <HeroLanding id="hero" ref={sectionRef} bgurl="3-hero.png">
            <LeasingDisplay padding={"290px 0px 0px 0px"}>
                <LeasingDivElement>
                    <LeasingParagraphsHero languagecode={currentLanguageCode} linehg={"80px"}>
                        {parse(t("SEIZE THE SPACE EMPOWER YOUR VISION"))}
                    </LeasingParagraphsHero>
                </LeasingDivElement>
                <LeasingJustifyContent padding={"20px 0px"}>
                    <Link to="/contact">
                        <ContactUsButtonComponent text={t("CONTACT US")} color={"#F4F4F4"} hidden={true} />
                    </Link>
                </LeasingJustifyContent>
            </LeasingDisplay>
        </HeroLanding>
    );
};

HeroSection.propTypes = {
    onHeightChange: PropTypes.func
};

export default HeroSection;
