import styled from "styled-components";
import { Carousel } from "antd";

export const FadeInOut = styled.div`
    animation: fadeIn 2.3s;
`;

// #region Hero/Landing section
export const HeroLanding = styled.div`
    background-image: url(./img/${(props) => props.bgurl});
    background-position: 50% 50%;
    background-size: cover;
    width: 100%;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    @media (max-width: 1366px) and (min-width: 1281px) {
        height: 768px;
    }

    @media (max-width: 1280px) {
        height: 100vh;
    }

    @media screen and (min-width: 1367px) {
        height: 100vh;
    }
`;

export const HeroWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100vh;
`;

export const HeroTextWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #fff;
    z-index: 1;
`;

export const HeroTitle = styled.p`
    color: #fff;
    font-family: "Barlow", Helvetica;
    font-size: 68px;
    font-weight: 600;
    letter-spacing: 0;
    text-align: center;
    text-shadow:
        0 0 5px rgba(255, 255, 255, 0.8),
        0 0 10px rgba(255, 255, 255, 0.6),
        0 0 15px rgba(255, 255, 255, 0.4),
        2px 2px 4px rgba(0, 0, 0, 0.5);
    white-space: nowrap;
`;

export const HeroPTag = styled.p`
    color: #fff;
    font-family: "Barlow", Helvetica;
    font-size: 22px;
    font-weight: 600;
    letter-spacing: 0;
    text-align: center;
    white-space: nowrap;
    text-shadow:
        0 0 5px rgba(255, 255, 255, 0.8),
        2px 2px 4px rgba(0, 0, 0, 0.5);
    padding: 10px 0;
`;

export const OverviewButtonDisplay = styled.div`
    position: absolute;
    bottom: ${(props) => props.bottom ?? "10%"};
    left: 50%;
    transform: translateX(-50%);
`;

export const ContactButtonComponent = styled.button`
    width: 150px;
    background-color: #cbe7f2;
    border: none;
    padding: 12px 20px;
    cursor: pointer;
    font-family: "Barlow", Helvetica, sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 18px;
    white-space: nowrap;
    text-transform: uppercase;
    color: #353535; /* Màu chữ */
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
    transition: all 0.3s ease;

    &:hover {
        font-size: 18px;
        transform: scale(1.1);
        box-shadow: 0 8px 20px rgba(0, 0, 0, 0.4);
        background-color: #a2d4f3;
        color: #353535;
        text-shadow: 1px 1px 6px rgba(0, 0, 0, 0.4);
    }
`;
// #endregion

// #region Overview section
export const OverviewSectionWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    height: ${(props) => props.height ?? "979px"};
    position: relative;
    background-color: #f4f4f4;
    z-index: 1000;

    @media (min-width: 480px) {
        height: 830px;
    }
`;

export const OverviewMainTextWrapper = styled.div`
    width: 1366px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 480px) {
        width: 100%;
    }
`;

export const OverviewImage = styled.img`
    height: ${(props) => props.height ?? ""};
    object-fit: ${(props) => props.objfit ?? ""};
    width: ${(props) => props.width};
    cursor: ${(props) => props.cursor ?? ""};
    border: ${(props) => props.border};
`;

export const OverviewVideoWrapper = styled.div`
    position: relative;
    width: 100%;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    height: 0;
    overflow: hidden;
`;

export const OverviewVideo = styled.iframe`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: ${(props) => props.objfit ?? ""};
    cursor: ${(props) => props.cursor ?? ""};
    border: ${(props) => props.border};
`;

export const OverviewTitle = styled.div`
    color: #353535;
    font-family: "Barlow", Helvetica;
    font-size: 50px;
    font-weight: 700;
    top: ${(props) => props.top ?? "104px"};
    left: ${(props) => props.left ?? "926px"};
    letter-spacing: 0;
    line-height: 50px;
    position: absolute;
    white-space: nowrap;
`;

export const OverviewDescription = styled.div`
    color: #353535;
    font-family: "Segoe UI", Helvetica;
    font-size: 21px;
    font-weight: 400;
    height: ${(props) => props.height ?? "245px"};
    letter-spacing: 0px;
    line-height: 32px;
    text-align: ${(props) => props.align ?? "left"};
    width: ${(props) => props.width ?? "472px"};
`;

export const OverviewBuildingProperty = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const OverviewGroupContent = styled.div`
    transition: transform 0.3s ease-in-out;
    animation: 1s ease-in-out infinite;
    width: ${(props) => props.width};
    height: ${(props) => props.height};
`;

export const OverviewBuildingPropertyImage = styled.img`
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    object-fit: cover;
`;

export const OverviewBuildingPropertyDescription = styled.div`
    color: ${(props) => props.color ?? "#2c53af"};
    font-family: ${(props) => (props.languagecode === "jp" ? "'Barlow', Helvetica" : props.font ?? "'Barlow', Helvetica")};
    font-size: ${(props) => props.fsize ?? "25px"};
    font-weight: ${(props) => props.fweight ?? "600"};
    letter-spacing: 0;
    line-height: ${(props) => props.linehg ?? "30px"};
    text-align: center;
    width: ${(props) => props.width};
    padding: 20px 0;
`;

export const SpanWrapper = styled.span`
    font-family: ${(props) => (props.languagecode === "jp" ? "'Barlow', Helvetica" : props.font ?? "'Segoe UI', Helvetica")};
    font-size: ${(props) => props.fsize ?? ""};
    font-weight: ${(props) => props.fweight ?? "400"};
`;
// #endregion

// #region Concept
export const ConceptSectionWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    height: ${(props) => props.height ?? "1740px"};
    position: relative;
    z-index: 999;
    padding: 0px 0px 50px 0px;
`;

export const ConceptWrapper = styled.div`
    width: 1366px;
    display: flex;
    height: inherit;
    flex-direction: column;
    align-items: center;
`;

export const ConceptTitle = styled.div`
    color: #353535;
    font-family: ${(props) => (props.languagecode === "jp" ? "'Barlow', Helvetica" : props.font ?? "'Barlow', Helvetica")};
    font-size: 47px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 47px;
    text-align: center;
    white-space: nowrap;
    padding: 100px 0 30px 0;
`;

export const ConceptDescription = styled.div`
    color: #353535;
    font-family: ${(props) => (props.languagecode === "jp" ? "'Barlow', Helvetica" : props.font ?? "'Segoe UI', Helvetica")};
    font-size: 16px;
    font-weight: 400;
    text-align: justify;
    text-align-last: left;
    height: ${(props) => props.height ?? "105px"};
    letter-spacing: 0px;
    line-height: 24px;
    width: ${(props) => props.width ?? "800px"};
`;
// #endregion

// #region Location section
export const LocationSectionWrapper = styled.div`
    display: flex;
    justify-content: center;
    position: relative;
    background-color: #f4f4f4;
    width: 100%;
    height: 1050px;
    z-index: 1;
`;

export const LocationWrapper = styled.div`
    width: 1366px;
    display: flex;
    height: inherit;
    flex-direction: column;
    align-items: center;
`;
// #endregion

// #region Commom & Mobile
export const OverviewMobile = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const OverviewMobileBackground = styled.div`
    background-color: ${(props) => props.bgcolor ?? "#f4f4f4"};
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const OverviewDisplayAlignItems = styled.div`
    display: flex;
    flex-direction: ${(props) => props.direction ?? "column"};
    align-items: center;
    margin: ${(props) => props.margin ?? ""};
`;

export const OverviewDivContent = styled.div`
    padding: ${(props) => props.padding ?? ""};

    @media (min-width: 768px) and (max-width: 1279px) {
        width: 100%;
    }
`;

export const OverviewIcon = styled.img`
    object-fit: cover;
    height: ${(props) => props.height ?? "70px"};
    width: ${(props) => props.width ?? "70px"};

    @media (min-width: 768px) and (max-width: 1279px) {
        height: 100px;
        width: 100px;
    }
`;

export const OverviewParagraphs = styled.div`
    color: ${(props) => props.color ?? "#353535"};
    letter-spacing: 0;
    text-align: ${(props) => props.talign ?? "justify"};
    font-family: ${(props) => (props.languagecode === "jp" ? "'Barlow', Helvetica" : props.font ?? "'Segoe UI', Helvetica")};
    font-size: ${(props) => props.fsize ?? "13px"};
    font-weight: ${(props) => props.fweight ?? "700"};
    line-height: ${(props) => props.linehg ?? "16px"};
    text-shadow: ${(props) => props.shadow ?? ""};
    padding: ${(props) => props.padding ?? ""};
    width: ${(props) => props.width ?? ""};

    @media (min-width: 768px) and (max-width: 1279px) {
        font-size: ${(props) => props.tablet ?? "17px"};
        line-height: ${(props) => (props.tablet ? "27px" : "25px")};
        width: 100%;
    }
`;

export const OverviewParagraphText = styled.p`
    color: ${(props) => props.color ?? "#353535"};
`;

export const HeroWrapperMobile = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
`;

export const HeroTitleMobile = styled.div`
    color: #fff;
    font-family: "Barlow", Helvetica;
    font-size: 47px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 47px;
    text-shadow:
        0 0 5px rgba(255, 255, 255, 0.8),
        0 0 10px rgba(255, 255, 255, 0.6),
        0 0 15px rgba(255, 255, 255, 0.4),
        2px 2px 4px rgba(0, 0, 0, 0.5);
    width: 100%;
    height: auto;
    text-align: center;
    margin-bottom: 10px;

    @media (min-width: 768px) and (max-width: 1279px) {
        font-size: 96px;
        line-height: 96px;
    }
`;

export const HeroTagMobile = styled.div`
    width: 100%;
    height: auto;
    color: #fff;
    letter-spacing: 0;
    text-align: center;
    font-family: "Barlow", Helvetica;
    font-size: 18px;
    font-weight: 700;
    line-height: 18px;
    text-shadow:
        0 0 5px rgba(255, 255, 255, 0.8),
        2px 2px 4px rgba(0, 0, 0, 0.5);

    @media (min-width: 768px) and (max-width: 1279px) {
        font-size: 22px;
        line-height: 30px;

        @media (min-width: 1024px) and (max-width: 1279px) {
            font-size: 30px;
            line-height: 30px;
        }
    }
`;

export const OverviewGrid = styled.div`
    display: grid;
    grid-template-columns: ${(props) => props.template ?? "150px 150px"};
    gap: ${(props) => props.gap ?? "70px"};
    text-align: center;
    padding: ${(props) => props.padding ?? ""};

    @media (min-width: 768px) and (max-width: 1279px) {
        grid-template-columns: 250px 250px;
    }
`;

export const OverviewInfoMobile = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;

export const OverviewTitleMobile = styled.div`
    color: #353535;
    letter-spacing: 0;
    white-space: nowrap;
    font-family: ${(props) => props.font ?? "'Barlow', Helvetica"};
    font-size: ${(props) => props.fsize ?? "27px"};
    font-weight: 700;
    line-height: ${(props) => props.fsize ?? "27px"};
    margin: ${(props) => props.margin};
    text-align: center;

    @media (min-width: 768px) and (max-width: 1279px) {
        font-size: 47px;
        line-height: 47px;
    }
`;

export const ConceptTitleMobile = styled.div`
    color: #353535;
    letter-spacing: 0;
    white-space: nowrap;
    font-family: ${(props) => props.font ?? "'Barlow', Helvetica"};
    font-size: ${(props) => props.fsize ?? "27px"};
    font-weight: 700;
    line-height: ${(props) => props.fsize ?? "27px"};
    margin: ${(props) => props.margin ?? "33px 0px 0px 0px"};
    text-align: center;

    @media (min-width: 768px) and (max-width: 1279px) {
        font-size: 37px;
        line-height: 37px;
    }
`;

export const OverviewDescriptionMobile = styled.div`
    color: #353535;
    letter-spacing: 0;
    font-family: ${(props) => (props.languagecode === "jp" ? "'Barlow', Helvetica" : "'Segoe UI', Helvetica")};
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    padding: ${(props) => props.padding ?? "10px 20px"};
    text-align: justify;
    text-align-last: left;
    @media (min-width: 768px) and (max-width: 1279px) {
        font-size: 18px;
        line-height: 27px;
    }
`;

export const OverviewButtonContact = styled.div`
    width: ${(props) => props.width ?? "170px"};
    height: ${(props) => props.height ?? "40px"};
    margin: 10px 0px;
    @media (min-width: 768px) and (max-width: 1279px) {
        width: 200px;
    }
`;

export const OverviewCarousel = styled(Carousel)`
    width: ${(prop) => prop.width ?? "100vw"};
    margin: ${(prop) => prop.margin};
    .slick-dots li.slick-active button {
        background: #154dac;
        width: 50px;
    }
    .slick-dots li button {
        background: #444444;
        width: 50px;
    }
    .slick-dots li {
        width: 50px;
    }
    .slick-dots li.slick-active {
        width: 50px;
    }
    .slick-dots {
        margin: 25px 0 0 0 !important;
        position: relative !important;
    }
    @media (min-width: 786px) and (max-width: 1279px) {
        width: ${(prop) => prop.tablet ?? "98vw"};
    }
`;

export const OverviewGroupBuildingArea = styled.div`
    height: 103px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const OverviewGroupBuildingAreaText = styled.div`
    color: #353535;
    letter-spacing: 0;
    text-align: right;
    white-space: nowrap;
    font-family: "Barlow", Helvetica;
    font-size: 40px;
    line-height: 40px;
    font-weight: 700;
    margin: 5px 0px;

    @media (min-width: 768px) and (max-width: 1279px) {
        font-size: 40px;
        line-height: 50px;
    }
`;

export const OverviewBuildingLegend = styled.div`
    width: 286px;
    color: #353535;
    letter-spacing: 0;
    text-align: right;
    white-space: nowrap;
    font-family: "Barlow", Helvetica;
    font-size: 14px;
    font-weight: 300;
    line-height: 14px;
    padding: 15px 0px;

    @media (min-width: 768px) and (max-width: 1279px) {
        font-size: 20px;
        line-height: 20px;
        padding: 0 0 20px 0;
    }
`;

export const OverviewBuildingImage = styled.div`
    width: 100%;
    height: auto;
    padding: ${(props) => props.padding ?? "10px 0"};
`;

export const OverviewGroupWrapper = styled.div`
    width: 100%;
    height: 108px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (min-width: 768px) and (max-width: 1279px) {
        height: 150px;
    }
`;

export const OverviewImageCustom = styled.img`
    width: 100%;
    object-fit: cover;
`;
export const OverviewIframeCustom = styled.iframe`
    width: 100%;
    object-fit: cover;
`;

export const OverviewBasement = styled.div`
    color: #2c53af;
    letter-spacing: 0;
    text-align: center;
    width: 157px;
    font-family: ${(props) => props.font ?? "'Barlow', Helvetica"};
    font-size: 11px;
    font-weight: 400;
    line-height: 15px;
    margin-top: 3px;

    @media (min-width: 768px) and (max-width: 1279px) {
        font-size: 20px;
        line-height: 20px;
        margin-top: 3px;
    }
`;

export const InfoBuildingPropertyWrapper = styled.div`
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: auto auto;
    gap: 10px;
    margin: 20px 20px 40px 20px;

    @media (min-width: 768px) and (max-width: 1279px) {
        gap: 20px;
        margin: 20px 10px 40px 10px;
    }
`;

export const OverviewDisplay = styled.div`
    display: flex;
    flex-direction: ${(props) => props.direction ?? "column"};
    margin: ${(props) => props.margin ?? ""};
`;

export const OverviewDisplayMargin = styled.div`
    display: flex;
    flex-direction: ${(props) => props.direction ?? "column"};
    margin: ${(props) => props.margin ?? "110px 0px 0px 0px"};

    @media (min-width: 481px) {
        margin: "10px 0px 0px 0px";
    }

    @media (min-width: 768px) and (max-width: 1279px) {
        margin: "10px 0px 0px 0px";
    }
`;

export const OverviewHeroButtonSection = styled.div`
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    @media (min-width: 360px) and (max-width: 1280px) {
        height: 90px;
        //margin: 10px  0px;
        justify-content: center;
        flex-direction: column;
    }
`;

export const OverviewDisplayJustify = styled.div`
    display: ${(props) => props.display ?? "flex"};
    flex-direction: ${(props) => props.direction ?? "row"};
    justify-content: ${(props) => props.justify ?? "center"};
    align-items: ${(props) => props.align ?? ""};
    padding: ${(props) => props.padding ?? ""};
    width: ${(props) => props.width ?? ""};
    height: ${(props) => props.height ?? ""};
    z-index: ${(props) => props.zindex ?? ""};
    gap: ${(props) => props.gap ?? ""};
    margin: ${(props) => props.margin ?? ""};
`;

export const OverviewSize = styled.div`
    width: ${(props) => props.width ?? "100%"};
    height: ${(props) => props.height ?? ""};

    @media (min-width: 768px) and (max-width: 1279px) {
        width: 100px;
        height: 100px;
    }
`;

export const OverviewMarginTop = styled.div`
    width: ${(props) => props.width ?? "100%"};
    text-align: center;
    margin-top: ${(props) => props.mt ?? "10px"};
`;
// #endregion
