import React, { useState, useEffect } from "react";
import "../../style/style.css";
import DesktopLayout from "../../components/DesktopLayout";
import * as Sections from "./Sections";
import * as MobileSections from "./Mobile.Sections";
import { useWindowWidth } from "../../breakpoints";
import { fetchMediasByType } from "../../api/media";
import { useLanguage } from "../../components/LanguageContext";

const MediaScreen = () => {
    const screenWidth = useWindowWidth();
    const [totalHeight, setTotalHeight] = useState(0);
    const [pressReleases, setPressReleases] = useState([]);
    // const [leasingEvents, setLeasingEvents] = useState([]);
    //const [constructionProgress, setConstructionProgress] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);
    const { selectedLang } = useLanguage();

    const handleHeightChange = (height) => {
        setTotalHeight(height);
    };

    const getMedias = async () => {
        const media = await fetchMediasByType("Media", selectedLang);
        if (media) {
            setPressReleases(media.pressReleaseVMs);
            // setLeasingEvents(media.leasingEventVMs);
            //setConstructionProgress(media.constructionProgressVMs);
            setDataLoaded(true);
        }
    };

    useEffect(() => {
        if (selectedLang || !dataLoaded) {
            getMedias();
        }
    }, [selectedLang, dataLoaded]);

    return (
        <>
            {screenWidth >= 1280 ? (
                <DesktopLayout screenWidth={screenWidth} screenHeight={`${totalHeight + 80}px`}>
                    <Sections.HeroSection onHeightChange={handleHeightChange} />
                    <Sections.NewsSection onHeightChange={handleHeightChange} datas={pressReleases} />
                    {/* <Sections.LeasingEventsSection onHeightChange={handleHeightChange} datas={leasingEvents} /> */}
                </DesktopLayout>
            ) : (
                <DesktopLayout screenWidth={screenWidth} screenHeight={"auto"}>
                    <MobileSections.HeroMobile />
                    <MobileSections.NewsMobile datas={pressReleases} />
                    {/* <MobileSections.LeasingEventsMobile datas={leasingEvents} /> */}
                </DesktopLayout>
            )}
        </>
    );
};
export default MediaScreen;
