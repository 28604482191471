import React, { useEffect } from "react";
import ContactUsButtonComponent from "../../../components/ContactUsButton";
import { Link } from "react-router-dom";
import { OverviewMobileBackground, OverviewDisplayAlignItems, InfoBuildingPropertyWrapper, OverviewInfoMobile, OverviewTitleMobile, OverviewDescriptionMobile, OverviewButtonContact, OverviewBuildingImage, OverviewImageCustom, OverviewGroupWrapper, OverviewBasement, OverviewSize, OverviewIframeCustom } from "../../../style/OverviewScreen";

import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

const OverviewMobile = () => {
    const { t, i18n } = useTranslation();
    const currentLanguageCode = i18n.language;

    useEffect(() => {
        i18n.changeLanguage(currentLanguageCode);
    }, []);

    return (
        <OverviewMobileBackground id="overview">
            <OverviewDisplayAlignItems>
                <OverviewInfoMobile>
                    <OverviewTitleMobile languagecode={currentLanguageCode} margin={"33px 0px 0px 0px"}>
                        {t("OVERVIEW")}
                    </OverviewTitleMobile>
                    <OverviewDescriptionMobile languagecode={currentLanguageCode} align={"justify"}>
                        {t("OVERVIEW DESCRIPTION")}
                    </OverviewDescriptionMobile>
                    <OverviewButtonContact height={"2px"}>
                        <Link to="/contact">
                            <ContactUsButtonComponent width={"100%"} text={t("CONTACT US")} hidden={true} />
                        </Link>
                    </OverviewButtonContact>
                </OverviewInfoMobile>
                <OverviewBuildingImage>
                    <OverviewIframeCustom width={100} height={400} src="https://www.youtube.com/embed/11uZ7sSYiLo?autoplay=1&loop=1&playlist=11uZ7sSYiLo"
                           title="TAISEI SQUARE HANOI - JAPANESE GRADE A OFFICE BUILDING IN HANOI, VIETNAM" frameborder="0" 
                           allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                           referrerpolicy="strict-origin-when-cross-origin" allowfullscreen />
                </OverviewBuildingImage>
                <InfoBuildingPropertyWrapper>
                    <OverviewGroupWrapper className="grid-item1">
                        <OverviewSize width={"50px"} height={"50px"}>
                            <OverviewImageCustom src="./img/office.png" />
                        </OverviewSize>
                        <OverviewBasement languagecode={currentLanguageCode}>{parse(t("18floors"))}</OverviewBasement>
                    </OverviewGroupWrapper>

                    <OverviewGroupWrapper className="grid-item2">
                        <OverviewSize width={"50px"} height={"50px"}>
                            <OverviewImageCustom src="./img/floorplate.png" />
                        </OverviewSize>
                        <OverviewBasement languagecode={currentLanguageCode}>{parse(t("Floorplate"))}</OverviewBasement>
                    </OverviewGroupWrapper>

                    <OverviewGroupWrapper className="grid-item3">
                        <OverviewSize width={"70px"} height={"70px"}>
                            <OverviewImageCustom src="./img/retail.png" />
                        </OverviewSize>
                        <OverviewBasement languagecode={currentLanguageCode}>{parse(t("2floors"))}</OverviewBasement>
                    </OverviewGroupWrapper>

                    <OverviewGroupWrapper className="grid-item4">
                        <OverviewSize width={"70px"} height={"70px"}>
                            <OverviewImageCustom src="./img/basement.png" />
                        </OverviewSize>
                        <OverviewBasement languagecode={currentLanguageCode}>{parse(t("4basement"))}</OverviewBasement>
                    </OverviewGroupWrapper>
                </InfoBuildingPropertyWrapper>
            </OverviewDisplayAlignItems>
        </OverviewMobileBackground>
    );
};

export default OverviewMobile;
